import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";

export class Navigation extends React.Component {
	changePage(pageId) {
		if (pageId !== localStorage.getItem('pageId')) {
            localStorage.setItem('pageId', pageId);
        }
		var click = this.props.onClick
		click(pageId);
	}

	render() {
		const currentClient = this.props.currentClient;
		const isRetailer = this.props.isRetailer;
		const portal = this.props.portal;
		const roles = this.props.roles;
		return (
			(this.props.currentClient) && (
				<Router>
					<ul>
			            <li className="level-1">
			            	{/* <i className="fas fa-chart-pie"></i> */}
							<p>Metrics</p>
			            

			            	{(roles.includes("portalAdm") || portal.leads === true || isRetailer === true) && (
			            		<React.Fragment>
						            <Link to="/metrics-leads" className="level-2 metrics-leads active" onClick={() => this.changePage("metrics-leads")}>
										<i className="far fa-address-book"></i> Leads: Web
									</Link>
									<Link to="/metrics-leadsChannel" className="level-2 metrics-leadsChannel" onClick={() => this.changePage("metrics-leadsChannel")}>
										<i className="far fa-address-book"></i> Leads: Web by Channel
									</Link>
									<Link to="/metrics-forms" className="level-2 metrics-forms" onClick={() => this.changePage("metrics-forms")}>
										<i className="far fa-address-book"></i> Leads: Web by Form
									</Link>
						        </React.Fragment>
			            	)}
			            	{(roles.includes("portalAdm") || portal.calls === true || isRetailer === true) && (
								<Link to="/metrics-calls" className="level-2 metrics-calls" onClick={() => this.changePage("metrics-calls")}>
									<i className="fas fa-mobile-alt"></i> Leads: Phone Calls
								</Link>
			            	)}
							{(roles.includes("portalAdm") || portal.samples === true || isRetailer === true) && (
								<Link to="/samples-leads" className="level-2 samples-leads" onClick={() => this.changePage("samples-leads")}>
									<i className="fas fa-address-book"></i> Leads: Samples
								</Link>
			            	)}
							{((roles.includes("portalAdm") || portal.emails === true) && (isRetailer === false)) && (
					            <Link to="/metrics-emails" className="level-2 metrics-emails" onClick={() => this.changePage("metrics-emails")}>
									<i className="fas fa-envelope-open"></i> Promotion Emails
								</Link>
		            		)}
							{((roles.includes("portalAdm") || portal.searchConsole === true) && (isRetailer === false)) && (
					            <Link to="/metrics-searchConsole" className="level-2 metrics-searchConsole" onClick={() => this.changePage("metrics-searchConsole")}>
									<i className="fas fa-search"></i> Search Console
								</Link>
		            		)}
		            		{((roles.includes("portalAdm") || portal.analytics === true) && (isRetailer === false)) && (
					            <Link to="/metrics-ga4analytics" className="level-2 metrics-ga4analytics" onClick={() => this.changePage("metrics-ga4analytics")}>
									<i className="fas fa-chart-line"></i> Google Analytics
								</Link>
		            		)}
		            		{(roles.includes("portalAdm") || portal.adwords === true || isRetailer === true) && (
					            <Link to="/metrics-adwords" className="level-2 metrics-adwords" onClick={() => this.changePage("metrics-adwords")}>
									<i className="fas fa-search-dollar"></i> Google Adwords
								</Link>
		            		)}
							{((roles.includes("portalAdm") || portal.googleMyBusiness === true) && (isRetailer === false)) && (
								<Link to="/metrics-gmb" className="level-2 metrics-gmb" onClick={() => this.changePage("metrics-gmb")}>
									<i className="fas fa-search-location"></i> Google My Business
								</Link>
							)}
							{((roles.includes("portalAdm") || portal.googleRatings === true) && (isRetailer === false)) && (
								<Link to="/metrics-googleRatings" className="level-2 metrics-googleRatings" onClick={() => this.changePage("metrics-googleRatings")}>
									<i className="fas fa-star-half-alt"></i> Google Ratings & Reviews
								</Link>
							)}
							{((roles.includes("portalAdm")) && (isRetailer === false)) && (
					            <Link to="/metrics-getstats" className="level-2 metrics-getstats" onClick={() => this.changePage("metrics-getstats")}>
									<i className="far fa-keyboard"></i> Keyword Analytics
								</Link>
		            		)}
		            		{(roles.includes("portalAdm") || portal.facebook === true || isRetailer === true) && (
					            <Link to="/metrics-socialFB" className="level-2 metrics-socialFB" onClick={() => this.changePage("metrics-socialFB")}>
									<i className="fab fa-facebook-square"></i> Facebook Advertising
								</Link>
		            		)}
							{((roles.includes("portalAdm") || portal.analytics === true) && (isRetailer === false)) && (
					            <Link to="/metrics-analytics" className="level-2 metrics-analytics" onClick={() => this.changePage("metrics-analytics")}>
									<i className="fas fa-chart-line"></i> Google Analytics (Historical)
								</Link>
		            		)}
		            		{(roles.includes("portalAdm") || portal.roomvo === true || isRetailer === false) && (
					            <Link to="/metrics-roomvo" className="level-2 metrics-roomvo" onClick={() => this.changePage("metrics-roomvo")}>
									<i className="fas fa-laptop-house"></i> Room Visualizer
								</Link>
		            		)}
							{( (roles.includes("portalAdm") || portal.rugsShop === true) && (currentClient.rugAffiliateCode !== null && currentClient.rugAffiliateCode !== "") ) && (
					            <Link to="/metrics-rugsShop" className="level-2 metrics-rugsShop" onClick={() => this.changePage("metrics-rugsShop")}>
									<i className="fas fa-dollar-sign"></i> Rugs Shop Sales
								</Link>
		            		)}
							{(isRetailer === false) && (
								<Link to="/metrics-summary" className="level-2 metrics-summary" onClick={() => this.changePage("metrics-summary")}>
									<i className="fas fa-tachometer-alt"></i> Performance Summary
								</Link>
							)}
						</li>
		            	{( roles.includes("portalAdm") && isRetailer === false ) && (
							<React.Fragment>
								<li className="level-1">
									{/* <i className="fas fa-book"></i>  */}
									<p>Content</p>
								
									<Link to="/content-blogs" className="level-2 content-blogs" onClick={() => this.changePage("content-blogs")}>
										<i className="fas fa-rss"></i> Blog Analytics
									</Link>
								</li>
							</React.Fragment>
						)}

				        {(currentClient.velocity === true && isRetailer === false) && (
							<li className="level-1">
			            	<Link to="/program" className="level-1 program" onClick={() => this.changePage("program")}>
								{/* <i className="fas fa-book-open"></i>  */}
								Program Details
							</Link>
							</li>
		            	)}
			        </ul>
		        </Router>
	       )
		)
	}
}

export default Navigation;